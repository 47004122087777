import React, { useEffect, useState } from "react";
import supabase from "./supabaseClient.js";

export default function SignIn() {
  const [user, setUser] = useState(null);

  const handleSignIn = async () => {
    const { data, error } = await supabase.auth.signInWithOAuth({
      provider: "google",
    });

    if (error) console.error(error);
  };

  async function getUser() {
    const {
      data: { user },
    } = await supabase.auth.getUser();
    return user;
  }

  async function handleSignOut() {
    const { error } = await supabase.auth.signOut();

    if (error) console.error(error);
    setUser(null);
  }

  useEffect(() => {
    const sessionUser = getUser();
    setUser(sessionUser);

    if (user) {
      console.log("signed in");
    }
  }, []);

  return user ? (
    <button onClick={handleSignOut}>Sign Out</button>
  ) : (
    <button onClick={handleSignIn}>Sign In with Google</button>
  );
}
